import React from 'react';
import './App.css';
import logo from './icn-popcorn-chrome.png';
import './App.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const { DateTime, Interval } = require("luxon");
const queryString = require('query-string');
const parsed = queryString.parse(window.location.search);
let lambdaUrl = "https://jsy47z9vfi.execute-api.us-east-1.amazonaws.com/default/DiscussionBar_Elastic";
let users = [];

let retry = 100;
let start=0;
var this_self;
var modified_on=false;
var last_modified_date; 
let versions = {};
let got_users = false;
let use_date = new Date(2019, 8,1);
let export_dict = {};
let export_posts = {};
let do_once = true;
let post_days = 0;
let internalAccounts = ['110812608668501404347','108462183545120417384','104990893854210270154','108146410229421299043',
'111875455832801032486','107625680837227606496','100226842784623943908','105443299401231104551',"103460703693691154057",
"111947267641898572841","108888320402813261298","108600272847473639641"];


function get_minutes_age(dt1, datetime )
{   
    var dt = DateTime.fromISO(datetime);
    var dt2 = DateTime.fromISO(dt1);

    var i = Interval.fromDateTimes(dt2, dt);
    var s = i.length('minutes');
    if (dt < dt2) {
        s = 0;
    }
    return parseInt(s);
}

function addExport(ed, dt) {
  for (const [key, value] of Object.entries(export_dict)) {
    if (value.start_date<=dt && value.end_date>dt) {
      value[ed] += 1;
    }
  }

}
function timeFormat(t) {
  let h = parseInt(t/3600);
  let m = parseInt((t-h*3600)/60);
  let s = parseInt(t-3600*h-60*m).toString().padStart(2,"0");
  let newsp = "";
  if (t>0) {
      if (h>0) {
          newsp = h + ":" + m.toString().padStart(2,"0") + ":" + s;
      } else {
          newsp = m + ":" + s;
      }
  } 
  return newsp;
}

function runAfterDelay() {
  setTimeout(function() {this_self.getPostCount("r","","",""); this_self.getPartyCount("r","","",""); this_self.runReport2()},1000);
}

 setInterval(function(){ if (got_users) { modified_on=true; last_modified_date = DateTime.local().plus({ minutes: -1 });this_self.getUsers(); this_self.getPostCount("n","","","")}}, 60000);
class App extends React.Component {
  constructor(props) {

    super(props);
    let dt =new Date();
    dt.setDate(dt.getDate());
    let dt2 =new Date();
    dt2.setDate(dt2.getDate() -1);
    this.state = {affiliate: "", startDate: dt2, endDate: dt, results_version: [], clicks: [], friend_pretty: [], install_pretty: [], install_pretty_tz: [], results_locale: [], results_zone: [], usersLoaded: 'block', dataDate: "", 
    total_dm: 0, total_group: 0, total_chat: 0, total_party: 0, total_post_count: 0, range_post_count: 0, today_post_count: 0, total_party_count: 0, range_party_count: 0, today_party_count: 0, total_private: 0, total_closed: 0, total_private_closed: 0, total_normal: 0, activity_pretty: [],
    has_friend_array: [0,0,0], installed_has_friend_array: [0,0,0], can_sync: {everyone:0,friends:0,noone:0},
    store_array: [0,0,0], affiliate_array: [0,0,0], homepage_array: [0,0,0], friend_array: [0,0,0], installed_array: [0,0,0], active_users: [], limit_tz: "", total_sketch: 0, total_reply: 0, total_likes: 0,
    affiliate_installed_array: [0,0,0], homepage_installed_array: [0,0,0], friend_installed_array: [0,0,0], 
    open_array: [0,0,0], total_activity: 0, today_activity: 0, excludeInternal: true};
    this.runReport = this.runReport.bind(this);
    this.runReport2 = this.runReport2.bind(this);
    this.doexport = this.doexport.bind(this);
    this.postexport = this.postexport.bind(this);
    this.postexport2 = this.postexport2.bind(this);
    this.getUsers = this.getUsers.bind(this);
    this.getPostCount = this.getPostCount.bind(this);
    this.getPartyCount = this.getPartyCount.bind(this);
    this_self = this;  
    this.handleInputChange = this.handleInputChange.bind(this);
    this.changetz = this.changetz.bind(this);
  }
  
  componentDidMount() {
    this.getUsers();
    this.getPostCount("a","","","");
    this.getPostCount("r","","","");
    this.getPostCount("n","","","");
    this.getPartyCount("a","","","");
    this.getPartyCount("r","","","");
    this.getPartyCount("n","","","");
    } 
  

  handleChange = date => {
    this.setState({
      startDate: date
    });
    runAfterDelay();
  };

  changetz = (event) => {
    this.setState({limit_tz: event.target.value});
    runAfterDelay();
  }

  handleChangeEnd = date => {
    this.setState({
      endDate: date
    });
    runAfterDelay();
  };

  handleInputChange(event) { 
    const target = event.target;
    const value = target.name === 'excludeInternal' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }
  
arrayUnique(array) {
    var a = array.concat();
    for(var i=0; i<a.length; ++i) {
        for(var j=i+1; j<a.length; ++j) {
            if(a[i] === a[j])
                a.splice(j--, 1);
        }
    }

    return a;
}

addUsers(data) {
  let ids=[];
  for (let lp=0; lp<users.length; lp++) {
    ids.push(users[lp]._id);
  }
  for (let lp=0; lp<data.length; lp++) {
    let i = ids.indexOf(data[lp]._id);
    if (i===-1) {
      users.push(data[lp]);
    } else {
      users[i] = data[lp];
    }
  }
}

getPartyCount(mode,dt1,dt2,dtst) {
  let self = this;
  let astr="";

    astr = lambdaUrl+"?action=get_party_count&key="+parsed.key
    if (mode === "n") {
      let d =new Date();
      d.setHours(0,0,0,0);
      let st = d.toISOString();
      d.setDate(d.getDate() + 1);
      let ed = d.toISOString();
      astr += "&date1="+st+"&date2="+ed;
    } else if (mode==="r") {
      let d =this.state.startDate;
      d.setHours(0,0,0,0);
      let e =this.state.endDate;
      e.setHours(0,0,0,0);
      let st = d.toISOString();
      let ed = e.toISOString();
      astr += "&date1="+st+"&date2="+ed;
    } else if (mode==="d") {
      let d =dt1;
      d.setHours(0,0,0,0);
      let e =dt2;
      e.setHours(0,0,0,0);
      let st = d.toISOString();
      let ed = e.toISOString();
      astr += "&date1="+st+"&date2="+ed;
    }
    fetch(astr)
    .then(function(response) {
      return response.json();
    }).then(function (data) {
      if (mode === "r") {
        self.setState({
          range_party_count: data.count
        });
       } else if (mode === "n") {
          self.setState({
            today_party_count: data.count
          });
      } else if (mode === "d") {
        export_dict[dtst].party_count = data.count;
      } else {
        self.setState({
          total_party_count: data.count
        });
      }

    }).catch(function (error) {
      self.getPartyCount(mode,dt1,dt2,dtst);     
    }
  )
}

getPostCount(mode,dt1,dt2,dtst) {
  let self = this;
  let astr="";


    astr = lambdaUrl+"?action=get_post_count&key="+parsed.key
    if (mode === "n") {
      let d =new Date();
      d.setHours(0,0,0,0);
      let st = d.toISOString();
      d.setDate(d.getDate() + 1);
      let ed = d.toISOString();
      astr += "&date1="+st+"&date2="+ed;
    } else if (mode==="r") {
      let d =this.state.startDate;
      d.setHours(0,0,0,0);
      let e =this.state.endDate;
      e.setHours(0,0,0,0);
      let st = d.toISOString();
      let ed = e.toISOString();
      astr += "&date1="+st+"&date2="+ed;
    } else if (mode==="d") {
      let d =dt1;
      d.setHours(0,0,0,0);
      let e =dt2;
      e.setHours(0,0,0,0);
      let st = d.toISOString();
      let ed = e.toISOString();
      astr += "&date1="+st+"&date2="+ed;
    }
    fetch(astr)
    .then(function(response) {
      return response.json();
    }).then(function (data) {
      if (mode === "r") {
        self.setState({
          range_post_count: data.count
        });
       } else if (mode === "n") {
          self.setState({
            today_post_count: data.count
          });
      } else if (mode === "d") {
          export_dict[dtst].post_count = data.count;
      } else {
        self.setState({
          total_post_count: data.count
        });
      }

    }).catch(function (error) {
        self.getPostCount(mode,dt1,dt2,dtst);     
      }
    )
}

getUsers() {

  this.setState({
    usersLoaded: "none"
  });
  let self = this;
  let astr="";
  if (modified_on) {
    astr = lambdaUrl+"?action=get_modified&startingrec="+start.toString()+"&key="+parsed.key+"&modified_date="+last_modified_date.toISO();
  } else {
    astr = lambdaUrl+"?action=get_all&date="+use_date.toISOString()+"&startingrec="+start.toString()+"&key="+parsed.key
  }
fetch(astr)
.then(function(response) {
  return response.json();
}).then(function (data) {
  self.addUsers(data);
  if (data.length===400) {
    start = start + 400;
    self.getUsers();
  } else {
    use_date.setDate(use_date.getDate() + 30);
    if (use_date < new Date()) {
      start = 0;
      self.getUsers();
      return;
    }
    let dt = new Date();
    self.setState({
      dataDate: dt.toString(),usersLoaded: ""
    });
    start = 0;
    got_users = true;
    self.runReport2();
    retry = 100;
  }
}).catch(function (error) {
  if (retry<=0) {
    retry = 100;
    alert('error getting data')
    self.setState({
      usersLoaded: ""
    });
  } else {
    retry -= 1;
    self.getUsers();     
  }
});
}


  runReport() {

    this.setState({
      usersLoaded: "none"
    });
    let self = this;
  fetch(lambdaUrl+"?action=get_all&startingrec="+start.toString()+"&key="+parsed.key)
  .then(function(response) {
    return response.json();
  }).then(function (data) {
    users = users.concat(data);
    if (data.length===200) {
      start = start + 200;
      self.runReport();
    } else {
      let dt = new Date();
      self.setState({
        dataDate: dt.toString()
      });
      retry = 100;
      self.runReport2();
    }
  }).catch(function (error) {
    if (retry<=0) {
      retry = 100;
      alert('error getting data')
      self.setState({
        usersLoaded: ""
      });
    } else {
      retry -= 1;
      self.runReport();     
    }
  });
  /*
    axios.get(lambdaUrl+"?action=get_all")
    .then(res => {
      users = res.data.hits.hits;
      let dt = new Date();
      this.setState({
        dataDate: dt.toString()
      });
      this.runReport2();
  });
  */
}

postexport() {
  export_posts = {};
  let dt =new Date();
  dt.setHours(0,0,0,0);
  let st_today = dt.toISOString();
  dt.setDate(dt.getDate() +1);
  dt.setHours(0,0,0,0);
  post_days = 46;
  let ed_today = dt.toISOString();
  let st_today_date = DateTime.fromISO(st_today);
  let ed_today_date = DateTime.fromISO(ed_today);
  export_posts[st_today.split('T')[0]] = {start_date: new Date(st_today_date.toISO()), end_date: new Date(ed_today_date.toISO()), total: 0, users: [], emoji: 0, marquee: 0, sketch: 0, user_age: []};
  this.postexport2(export_posts[st_today.split('T')[0]].start_date, export_posts[st_today.split('T')[0]].end_date,st_today.split('T')[0],0);
  let dt2 =new Date();
  dt2.setHours(0,0,0,0);
  for (let i=0; i<45; i++) {
    let edt = new Date(dt2.getTime());
    dt2.setDate(dt2.getDate() -1);
    let st_dt = dt2.toISOString();
    export_posts[st_dt.split('T')[0]] = {start_date: new Date(dt2.getTime()), end_date: edt, total: 0, users: [], emoji: 0, marquee: 0, sketch: 0, user_age: []};
    this.postexport2(export_dict[st_dt.split('T')[0]].start_date, export_dict[st_dt.split('T')[0]].end_date,st_dt.split('T')[0],0);
  }
}

digestPosts(data,dt) {
  export_posts[dt].total += data.hits.total.value;
  let hits = data.hits.hits;
  var dtuse = DateTime.fromISO(dt);
  for (let i=0; i<hits.length; i++) {
    let post = hits[i];
    let uid = post._source.user._id;
    if (!export_posts[dt].users.includes(uid)) {
      export_posts[dt].users.push(uid);
      for (let j=0; j<users.length; j++) {
        if (users[j]._id == uid) {
          let dt2 = DateTime.fromISO(users[j]._source.date);
          var it = Interval.fromDateTimes(dt2, dtuse);
          var s = it.length('days');
          if (isNaN(s)) {
            s = 0;
          } else {
            s = parseInt(s);
          }
          export_posts[dt].user_age.push(s);

          break;
        }
      }
    }
    if (post._source.emoji) {
      export_posts[dt].emoji += 1;
    }
    if (post._source.marquee) {
      export_posts[dt].marquee += 1;
    }
    if (post._source.active_sketch) {
      export_posts[dt].sketch += 1;
    }
  }
}
postexport2 (dt1,dt2,dtst,start) {
  let self = this;
  let astr="";

  astr = lambdaUrl+"?action=get_post_export&startingrec="+start.toString()+"&key="+parsed.key
  let d =dt1;
  d.setHours(0,0,0,0);
  let e =dt2;
  e.setHours(0,0,0,0);
  let st = d.toISOString();
  let ed = e.toISOString();
  astr += "&date1="+st+"&date2="+ed;
fetch(astr)
.then(function(response) {
  return response.json();
}).then(function (data) {
  self.digestPosts(data,dtst);
  if (data.length===400) {
    self.postexport2(dt1,dt2,dtst,start+400);
  } else {
    post_days -= 1;
    if (post_days == 0) {
      let rows = [['Date','Posts','Users','Emojis','Marquee','Sketch','New Users','Average Age']];
      for (const [key, value] of Object.entries(export_posts)) {
        let z = 0;
        let t = 0;
        for (let i=0; i<value.user_age.length; i++) {
          if (value.user_age[i] == 0) {
            z += 1;
          } else {
            t += value.user_age[i];
          }
        }
        let t2 = 0;
        if (t>0) {
          t2 = t/value.user_age.length;
        }
        rows.push([key,value.total,value.users.length,value.emoji,value.marquee,value.sketch, z, t2])
      }
    
    let csvContent = "data:text/csv;charset=utf-8," 
        + rows.map(e => e.join(",")).join("\n");
        var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "pocorn_post_data.csv");
    document.body.appendChild(link); // Required for FF
    
    link.click();
    }
    retry = 100;
  }
}).catch(function (error) {
  if (retry<=0) {
    retry = 100;
    alert('error getting data');
  } else {
    retry -= 1;
    self.postexport2(dt1,dt2,dtst,start);
  }
});
}

doexport () {
  let rows = [['Date','New Accounts','New w/ Login','New from Chrome Store','New from joinpopcorn','From joinporn w/ login','New from Friend Page','From Friend Page w/ login','Has a Friend','Has a Friend & Login','Party Count','Post Count']];
  for (const [key, value] of Object.entries(export_dict)) {
    rows.push([key,value.new,value.new_login,value.chrome_store,value.homepage,value.homepage_login,value.friendpage,value.friendpage_login,value.has_friend, value.has_friend_login,value.party_count,value.post_count])
  }

let csvContent = "data:text/csv;charset=utf-8," 
    + rows.map(e => e.join(",")).join("\n");
    var encodedUri = encodeURI(csvContent);
var link = document.createElement("a");
link.setAttribute("href", encodedUri);
link.setAttribute("download", "pocorn_data.csv");
document.body.appendChild(link); // Required for FF

link.click();
}
runReport2() {
    let d =this.state.startDate;
    d.setHours(0,0,0,0);
    let e =this.state.endDate;
    e.setHours(0,0,0,0);
    let st = d.toISOString();
    let ed = e.toISOString();
    let dt =new Date();
    dt.setHours(0,0,0,0);
    let st_today = dt.toISOString();
    dt.setDate(dt.getDate() +1);
    dt.setHours(0,0,0,0);
    let ed_today = dt.toISOString();
    let ids=[];
    let click_totals={};
    let total_dm=0;
    let total_group=0;
    let total_chat=0;
    let total_party=0;
    let open_array=[0,0,0];
    let total_activity=0;
    let today_activity=0;
    let total_private=0;
    let total_private_closed=0;
    let total_closed=0;
    let total_normal=0;
    let zones = {};
    let active_users = [];
    let store_array= [0,0,0], homepage_array= [0,0,0], affiliate_array= [0,0,0], friend_array= [0,0,0], installed_array= [0,0,0], has_friend_array=[0,0,0], installed_has_friend_array=[0,0,0];
    let affiliate_installed_array= [0,0,0], homepage_installed_array= [0,0,0], friend_installed_array= [0,0,0];
    let st_today_date = DateTime.fromISO(st_today);
    let ed_today_date = DateTime.fromISO(ed_today);
    let st_date = DateTime.fromISO(st);
    let ed_date = DateTime.fromISO(ed);
 //   export_dict = {};
    export_dict[st_today.split('T')[0]] = {'new': 0, 'new_login': 0, 'friendpage': 0, 'homepage': 0, 'friendpage_login': 0, 'homepage_login': 0, 'chrome_store': 0, 'has_friend': 0, 'has_friend_login': 0, start_date: new Date(st_today_date.toISO()), end_date: new Date(ed_today_date.toISO())};
    let dt2 =new Date();
    dt2.setHours(0,0,0,0);
    this.getPartyCount("d",export_dict[st_today.split('T')[0]].start_date, export_dict[st_today.split('T')[0]].end_date,st_today.split('T')[0]);
    this.getPostCount("d",export_dict[st_today.split('T')[0]].start_date, export_dict[st_today.split('T')[0]].end_date,st_today.split('T')[0]);

    if (do_once) {
      do_once = false;
      for (let i=0; i<45; i++) {
        let edt = new Date(dt2.getTime());
        dt2.setDate(dt2.getDate() -1);
        let st_dt = dt2.toISOString();
        export_dict[st_dt.split('T')[0]] = {'new': 0, 'new_login': 0, 'friendpage': 0, 'homepage': 0, 'friendpage_login': 0, 'homepage_login': 0, 'chrome_store': 0, 'has_friend': 0, 'has_friend_login': 0, start_date: new Date(dt2.getTime()), end_date: edt};
        this.getPartyCount("d",export_dict[st_dt.split('T')[0]].start_date, export_dict[st_dt.split('T')[0]].end_date,st_dt.split('T')[0]);
        this.getPostCount("d",export_dict[st_dt.split('T')[0]].start_date, export_dict[st_dt.split('T')[0]].end_date,st_dt.split('T')[0]);
      }
    }
    let install_hours=[];
    let install_hours_tz=[];
    let friend_counts = [];
    let follower_counts = [];
    let following_counts = [];
    let activity_counts = [];
    let installed_today = [];
    let locales={};
    let content_counts = {};
    let can_sync = {everyone:0,friends:0,noone:0};
    let limit_tz = this.state.limit_tz.trim();

    versions = {};
    click_totals['marquee'] = 0;
    click_totals['bio'] = 0;
    click_totals['auto_skip'] = 0;
    for (let lp=0; lp< 24; lp++) {
      install_hours[lp]={today: 0, range:0, total: 0};
      install_hours_tz[lp]={today: 0, range:0, total: 0};
    }
    for (let lp=0; lp<= 10; lp++) {
      friend_counts[lp]=0;
      follower_counts[lp]=0;
      following_counts[lp]=0;

    }
    for (let lp=0; lp<= 10; lp++) {
      activity_counts[lp]={normal: 0, private: 0, closed: 0, private_closed: 0, last_visible: 0, last_open: 0};
    }

    for (let i=0; i<users.length; i++) {
      try {
        if ((!('_source' in users[i] ))) {
          continue;
        }
      let user =users[i]._source;
      if (!('date' in user) || (!('id' in user))) {
        continue;
      }
      let u_date = DateTime.fromISO(users[i]._source.date);
      if ('is_deleted' in user && !user.is_deleted) {
        open_array[2] += 1;
      } else {
        continue;
      }
      if (this.state.excludeInternal) {
        if (internalAccounts.includes(user.id)) {
          continue;
        }
      }
      if ('bio' in user && user.bio>"") {
        click_totals['bio'] += 1;
      }
      if ('auto_skip' in user && user.auto_skip) {
        click_totals['auto_skip'] += 1;
      }
      if ('can_sync' in user) {
        can_sync[user.can_sync] += 1;
      }
      if ('is_deleted' in user && !user.is_deleted) {
        if ('activity_date' in users[i]._source) {
          total_activity += 1;
          let norm=true;
          let priv_open = false;
          let act_age = get_minutes_age(users[i]._source.activity_date,DateTime.local().toISO());
          let act_cat = 0;
          if (act_age < 1) {
            act_cat =0;
          } else if (act_age < 10) {
            act_cat = 1;
          } else if (act_age < 30) {
            act_cat = 2;
          } else if (act_age < 60) {
            act_cat = 3;
          } else if (act_age < 180) {
            act_cat = 4;
          } else if (act_age < 600) {
            act_cat = 5;
          } else if (act_age < 1440) {
            act_cat = 6;
          } else if (act_age < 2880) {
            act_cat = 7;
          } else if (act_age < 7200) {
            act_cat = 8;
          } else if (act_age < 14400) {
            act_cat = 9;
          } else {
            act_cat = 10;
          }
          if (('privacy_mode' in user && user.privacy_mode) && ('now' in user && 'closed' in user.now && user.now.closed)) {
        //    total_private_closed += 1;
        //    activity_counts[act_cat].private_closed += 1; 
            total_closed += 1;
            activity_counts[act_cat].closed += 1; 
            norm=false;
          } else {
            if ('privacy_mode' in user && user.privacy_mode) {
              total_private += 1;
              activity_counts[act_cat].private += 1; 
              norm = false;
              priv_open = true;
            }
            if ('now' in user && 'closed' in user.now && user.now.closed) {
              total_closed += 1;
              activity_counts[act_cat].closed += 1; 
              norm = false;
            }
          }
          if (norm) {
            total_normal += 1;   
            activity_counts[act_cat].normal += 1;    
            if (act_cat === 0) {
              let s =user.now.content_title;
              let t ="";
              if (s>"") {
                t =user.now.time;
                let d = user.activity_date;
                if (d && !(user.now.pause || user.now.playing_ad)) {
                  let d2 = new Date();
                  let d1 = new Date(d);
                  let x = parseFloat((d2-d1)/1000);
                  t = parseFloat(t) + x;                
                }  
                t = timeFormat( t);
              }
              active_users.push({user_id: user.id, name: user.name, content: user.now.content_title, id: user.now.content_id, time: t});
              if (!(user.now.content_id in content_counts)) {
                content_counts[user.now.content_id] = 0;  
              }
              content_counts[user.now.content_id] +=1;  
            }    
          }
          let dt_use = user.activity_date;
          if (norm || 'last_visible_date' in user) {
            if ('last_visible_date' in user) {
              dt_use = user.last_visible_date;
            }
            act_age = get_minutes_age(dt_use,DateTime.local().toISO());
            act_cat = 0;
            if (act_age < 1) {
              act_cat =0;
            } else if (act_age < 10) {
              act_cat = 1;
            } else if (act_age < 30) {
              act_cat = 2;
            } else if (act_age < 60) {
              act_cat = 3;
            } else if (act_age < 180) {
              act_cat = 4;
            } else if (act_age < 600) {
              act_cat = 5;
            } else if (act_age < 1440) {
              act_cat = 6;
            } else if (act_age < 2880) {
              act_cat = 7;
            } else if (act_age < 7200) {
              act_cat = 8;
            } else if (act_age < 14400) {
              act_cat = 9;
            } else {
              act_cat = 10;
            }
            activity_counts[act_cat].last_visible += 1; 
          }
          dt_use = user.activity_date;
          if (norm || priv_open || 'last_open_date' in user) {
            if ('last_open_date' in user) {
              dt_use = user.last_open_date;
            }
            act_age = get_minutes_age(dt_use,DateTime.local().toISO());
            act_cat = 0;
            if (act_age < 1) {
              act_cat =0;
            } else if (act_age < 10) {
              act_cat = 1;
            } else if (act_age < 30) {
              act_cat = 2;
            } else if (act_age < 60) {
              act_cat = 3;
            } else if (act_age < 180) {
              act_cat = 4;
            } else if (act_age < 600) {
              act_cat = 5;
            } else if (act_age < 1440) {
              act_cat = 6;
            } else if (act_age < 2880) {
              act_cat = 7;
            } else if (act_age < 7200) {
              act_cat = 8;
            } else if (act_age < 14400) {
              act_cat = 9;
            } else {
              act_cat = 10;
            }
            activity_counts[act_cat].last_open += 1; 
          }
          let a_date = DateTime.fromISO(users[i]._source.activity_date);
          if (a_date>=st_today_date && a_date< ed_today_date) {     
            today_activity += 1; 
            if ('now' in user && 'version' in user.now) {
              if (!(user.now.version in versions)) {
                versions[user.now.version] = 0;  
              }
              versions[user.now.version] += 1;
            }    
          }
        }
        let in_range=false;
        let is_today = false;
        if (u_date>=st_date && u_date< ed_date) {
          in_range = true;
        }
        if (u_date>=st_today_date && u_date< ed_today_date) {
          is_today = true;
        }
        addExport('new',u_date);
        if (is_today) {
          open_array[0] += 1;
        }
        if (in_range) {
          open_array[1] += 1;
        }
        if ('referral' in user) {
          if (user.referral === 'affiliate') {
            affiliate_array[2] += 1;
            if (is_today) {
              affiliate_array[0] += 1;
            } 
            if (in_range) {
              affiliate_array[1] += 1;
            }    
          } else if (user.referral === 'friend' || user.referral === 'follow') {
            addExport('friendpage',u_date);
            friend_array[2] += 1;
            if (is_today) {
              friend_array[0] += 1;
            } 
            if (in_range) {
              friend_array[1] += 1;
            } 
          } else {
            addExport('homepage',u_date);
            homepage_array[2] += 1;
            if (is_today) {
              homepage_array[0] += 1;
            } 
            if (in_range) {
              homepage_array[1] += 1;
            }  
          }
        } else {
          addExport('chrome_store',u_date);
          store_array[2] += 1;
          if (is_today) {
            store_array[0] += 1;
          } 
          if (in_range) {
            store_array[1] += 1;
          }    
        }
        if (user.logins > 0 ) { 
          installed_array[2] += 1;
          addExport('new_login',u_date);
          if (is_today) {
            installed_array[0] += 1;
            installed_today.push(users[i]._id);
          }
          if (in_range) {
            installed_array[1] += 1;
          }

          if ('referral' in user) {
            if (user.referral === 'affiliate') {
              affiliate_installed_array[2] += 1;
              if (is_today) {
                affiliate_installed_array[0] += 1;
              }
              if (in_range) {
                affiliate_installed_array[1] += 1;
              }    
            } else if (user.referral === 'friend' || user.referral === 'follow') {
              addExport('friendpage_login',u_date);
              friend_installed_array[2] += 1;
              if (is_today) {
                friend_installed_array[0] += 1;
              }
              if (in_range) {
                friend_installed_array[1] += 1;
              }  
            } else {
              addExport('homepage_login',u_date);
              homepage_installed_array[2] += 1;
              if (is_today) {
                homepage_installed_array[0] += 1;
              }
              if (in_range) {
                homepage_installed_array[1] += 1;
              } 
            }
          }
        }
        if (('friend_ids' in user && user.friend_ids.length>0) || ('followers' in user && user.followers.length>0) || ('following' in user && user.following.length>0) ) {
          addExport('has_friend',u_date);
          if (is_today) {
            has_friend_array[0] += 1;
          }
          if (in_range) {
            has_friend_array[1] += 1;
          }
          has_friend_array[2] += 1;
          if (user.logins>0) {
            let fc = 0;
            addExport('has_friend_login',u_date);
            if ('friend_ids' in user && user.friend_ids.length>0) {
              fc = user.friend_ids.length;
              if (fc>10) {
                fc = 10;
              }
              friend_counts[fc] += 1;
            }
            if ('followers' in user && user.followers.length>0) {
              fc = user.followers.length;
              if (fc>10) {
                fc = 10;
              }
              follower_counts[fc] += 1;
            }
            if ('following' in user && user.following.length>0) {
              fc = user.following.length;
              if (fc>10) {
                fc = 10;
              }
              following_counts[fc] += 1;
            }
            if (is_today) {
              installed_has_friend_array[0] += 1;
            }
            if (in_range) {
              installed_has_friend_array[1] += 1;
            }
            installed_has_friend_array[2] += 1;
          }
        } else if (user.logins>0) {
          friend_counts[0] += 1;
          follower_counts[0] += 1;
          following_counts[0] += 1;
        }
        let z = user.date.substr(23);
        let do_h = false;
        if (limit_tz === "" || limit_tz === z) {
          do_h = true;
        }
          let h= DateTime.fromISO(user.date).hour;
          if (do_h && is_today) {
            install_hours[h].today += 1;       
          }  
          if (do_h && in_range) {
            install_hours[h].range += 1;       
          }  

          if (!(z in zones)) {
            zones[z]= {today: 0, range: 0, total: 0};
          }
          let do_z = false;
          if (z !=="Z" && (limit_tz === "" || limit_tz === z)) {
            do_z = true;
          }
          if (is_today) {
            if (do_z) {
              install_hours_tz[parseInt(user.date.substr(11,2))].today += 1;
            } 
            zones[z].today += 1; 
          }
          if (in_range) {
            if (do_z) {
              install_hours_tz[parseInt(user.date.substr(11,2))].range += 1;
            } 
            zones[z].range += 1; 
          }
          if (do_z) {
            install_hours_tz[parseInt(user.date.substr(11,2))].total += 1;
          }
          
          zones[z].total += 1; 
      }
        let do_h = false;
        let z = user.date.substr(23);
        if (limit_tz === "" || limit_tz === z) {
          do_h = true;
        }
        let h= DateTime.fromISO(user.date).hour;
        if (do_h) {
          install_hours[h].total += 1;
        }
        if (!(user.locale in locales)) {
          locales[user.locale] = 0;
        }
        locales[user.locale] += 1;

        let prev =  ids.includes(user.id);
        if (!prev) {
          ids.push(user.id);
        }

  
      if ('tab_clicks' in user) {
        let ks = Object.keys(user.tab_clicks);
        for (let k=0; k<ks.length; k++ ) {
          if (ks[k] !== 'total') {
            if (!(ks[k] in click_totals)) {
              click_totals[ks[k]] = 0;
            }
            click_totals[ks[k]] += 1;
          }
        }
      }
    } catch(e) {
      console.log(e);
    }
  }
    let install_pretty = [];
    let install_pretty_tz=[];
    for (let lp=0; lp< install_hours.length; lp++) {
      let s = "";
      if (lp===0) {
        s = "12am";
      } else if (lp< 12) {
        s = lp.toString()+"am";
      } else if (lp===12) {
        s = "12pm";
      } else {
        s = (lp-12).toString()+"pm";
      }
      install_pretty.push({id: s, "name": s, "today": install_hours[lp].today, "range": install_hours[lp].range, "total": install_hours[lp].total});
    }
    for (let lp=0; lp< install_hours_tz.length; lp++) {
      let s = "";
      if (lp===0) {
        s = "12am";
      } else if (lp< 12) {
        s = lp.toString()+"am";
      } else if (lp===12) {
        s = "12pm";
      } else {
        s = (lp-12).toString()+"pm";
      }
      install_pretty_tz.push({id: s, "name": s, "today": install_hours_tz[lp].today, "range": install_hours_tz[lp].range, "total": install_hours_tz[lp].total});
    }
    let friend_pretty = [];
    for (let lp=0; lp< friend_counts.length; lp++) {
      let s = lp;
      if (lp === 10) {
        s = "10+"
      }
      friend_pretty.push({id: "friend-"+lp, "name": s, "total": friend_counts[lp], "followers": follower_counts[lp], "following": following_counts[lp]});
    }

    let activity_pretty = [];
    for (let lp=0; lp< activity_counts.length; lp++) {
      let s="";
      if (lp === 0) {
        s = "Less than 1 minute";
      } else if (lp === 1) {
        s = "1-10 minutes";
      } else if (lp === 2) {
        s = "10-30 minutes";
      } else if (lp === 3) {
        s = "30-60 minutes";
      } else if (lp === 4) {
        s = "1-3 hours";
      } else if (lp === 5) {
        s = "3-10 hours";
      } else if (lp === 6) {
        s = "10-24 hours";
      } else if (lp === 7) {
        s = "1-2 days";
      } else if (lp === 8) {
        s = "2-5 days";
      } else if (lp === 9) {
        s = "5-10 days";
      } else if (lp === 10) {
        s = "Over 10 days";
      }
      activity_pretty.push({id: "activity-"+lp, "name": s, "normal": activity_counts[lp].normal, "private": activity_counts[lp].private, "closed": activity_counts[lp].closed, "private_closed": activity_counts[lp].private_closed,
      "last_visible": activity_counts[lp].last_visible, "last_open": activity_counts[lp].last_open});
    }
    let results_locale = [];
    for (let k in locales) {
      results_locale.push({id: k, name: k, total: locales[k]})   
    } 
    let results_zone = [];
    for (let k in zones) {
      results_zone.push({id: k, name: k, today: zones[k].today, range: zones[k].range, total: zones[k].total})   
    } 

    let clicks = [];
    let i=0;
    for (let k in click_totals) {
      clicks.push({id: i, name: k, total: click_totals[k]});
      i +=1;
    }

    let results_version=[];
    for (let k in versions) {
      results_version.push({id: i, name: k, total: versions[k]});
      i +=1;
    }

    this.setState({usersLoaded: "", results_version: results_version.sort(function(a, b){ if (a.name<b.name) { return 1} else { return -1}
        })    , total_dm: total_dm, total_group: total_group, total_chat: total_chat, total_party: total_party,open_array: open_array, 
        results_locale: results_locale, results_zone: results_zone.sort(function(a, b){ if (a.name<b.name) { return 1} else { return -1}
      })
        , friend_pretty: friend_pretty, 
        total_private: total_private, total_normal: total_normal, total_closed: total_closed, total_private_closed: total_private_closed,  install_pretty: install_pretty, install_pretty_tz: install_pretty_tz,
        store_array: store_array, affiliate_array: affiliate_array, homepage_array: homepage_array, friend_array: friend_array,
        can_sync: can_sync,
        has_friend_array: has_friend_array, installed_has_friend_array: installed_has_friend_array,activity_pretty: activity_pretty,
        active_users: active_users.sort(function(a, b){
          if (b.content === "") { return -1} else if (a.content === "") { return 1} else if (content_counts[a.id].toString().padStart(3, '0')+a.content<content_counts[b.id].toString().padStart(3, '0')+b.content) { return 1} else { return -1}
        }),
        affiliate_installed_array: affiliate_installed_array, homepage_installed_array: homepage_installed_array, friend_installed_array: friend_installed_array,
        total_activity: total_activity, today_activity: today_activity, 
        installed_array: installed_array, 
    clicks: clicks
  });
  };


  render() {
    const report_clicks = this.state.clicks.map((item, key) => <tr key={item.id}><td style={{textAlign: "left","paddingLeft": "5px"}}>{item.name}</td><td>{item.total}</td></tr>);
    const report_version = this.state.results_version.map((item, key) => <tr key={item.id}><td style={{textAlign: "left","paddingLeft": "5px"}}>{item.name}</td><td>{item.total}</td></tr>);
    const report_hours = this.state.install_pretty.map((item, key) => <tr key={item.id}><td style={{textAlign: "left","paddingLeft": "5px"}}>{item.name}</td><td>{item.today}</td><td>{item.range}</td><td>{item.total}</td></tr>);
    const report_hours_tz = this.state.install_pretty_tz.map((item, key) => <tr key={item.id}><td style={{textAlign: "left","paddingLeft": "5px"}}>{item.name}</td><td>{item.today}</td><td>{item.range}</td><td>{item.total}</td></tr>);
    const report_locales = this.state.results_locale.map((item, key) => <tr key={item.id}><td style={{textAlign: "left","paddingLeft": "5px"}}>{item.name}</td><td>{item.total}</td></tr>);
    const report_zone = this.state.results_zone.map((item, key) => <tr key={item.id}><td style={{textAlign: "left","paddingLeft": "5px"}}>{item.name}</td><td>{item.today}</td><td>{item.range}</td><td>{item.total}</td></tr>);
    const report_friends = this.state.friend_pretty.map((item, key) => <tr key={item.id}><td style={{textAlign: "left","paddingLeft": "5px"}}>{item.name}</td><td>{item.total}</td><td>{item.followers}</td><td>{item.following}</td></tr>);
    const report_activity = this.state.activity_pretty.map((item, key) => <tr key={item.id}><td style={{textAlign: "left","paddingLeft": "5px"}}>{item.name}</td><td>{item.normal}</td><td>{item.private}</td><td>{item.closed}</td><td>{item.last_visible}</td><td>{item.last_open}</td></tr>);

return (

      <div className="App">
      <header className="App-header">
        <img height="50" width="50" src={logo}  alt="logo" />
        <div>
          PopCorn Reports<br></br>
          <span style={{fontSize: "14px"}}>User Data as of {this.state.dataDate}</span>
        </div>

<div style={{fontSize: "14px", marginRight: "15px"}}>Compare Date Range:
        <span style={{fontSize: "14px", marginLeft: "10px"}}>
        <DatePicker
        selected={this.state.startDate}
        onChange={this.handleChange}
      />
      </span>
       <span style={{fontSize: "14px", marginLeft: "10px"}}>until </span>
      <span style={{fontSize: "14px", marginLeft: "10px"}}>
        <DatePicker
        selected={this.state.endDate}
        onChange={this.handleChangeEnd}

      />
      </span>
      </div>
      <div>
      <label style={{fontSize: "14px"}}>
  Exclude Internal Accounts:
          <input
            name="excludeInternal"
            type="checkbox"
            checked={this.state.excludeInternal}
            onChange={this.handleInputChange} />
        </label>
      </div>
      <div>
      <button style={{marginTop:"10px",fontSize: "20px", display: "none" }} onClick={this.runReport2}>
        Go
      </button>
      </div>

      </header>

      <table style={{marginLeft: "10px", marginTop: "20px", borderStyle: "solid"}}>
        <thead><tr><th style={{width: "20%"}}></th>
        <th style={{width: "10%"}}>Today</th>
        <th style={{width: "10%"}}>Compare</th>
        <th style={{width: "10%"}}>Total</th></tr></thead>
        <tbody>
          <tr><td>New Accounts</td><td>{ this.state.open_array[0]}</td><td>{ this.state.open_array[1]}</td><td>{ this.state.open_array[2]}</td></tr>
          <tr><td>New w/ a Login</td><td>{ this.state.installed_array[0]}</td><td>{ this.state.installed_array[1]}</td><td>{ this.state.installed_array[2]}</td></tr>
          <tr><td>Posts</td><td>{ this.state.today_post_count}</td><td>{ this.state.range_post_count}</td><td>{ this.state.total_post_count}</td></tr>
          <tr><td>Parties Created</td><td>{ this.state.today_party_count}</td><td>{ this.state.range_party_count}</td><td>{ this.state.total_party_count}</td></tr>
          <tr><td>New From Chrome Store</td><td>{ this.state.store_array[0]}</td><td>{ this.state.store_array[1]}</td><td>{ this.state.store_array[2]}</td></tr>
          <tr><td>New From joinpopcorn.com</td><td>{ this.state.homepage_array[0]}</td><td>{ this.state.homepage_array[1]}</td><td>{ this.state.homepage_array[2]}</td></tr>
          <tr><td>New From Friend Link</td><td>{ this.state.friend_array[0]}</td><td>{ this.state.friend_array[1]}</td><td>{ this.state.friend_array[2]}</td></tr>
          <tr><td>New From joinpopcorn w/ Login</td><td>{ this.state.homepage_installed_array[0]}</td><td>{ this.state.homepage_installed_array[1]}</td><td>{ this.state.homepage_installed_array[2]}</td></tr>
          <tr><td>New From Friend Link w/ Login</td><td>{ this.state.friend_installed_array[0]}</td><td>{ this.state.friend_installed_array[1]}</td><td>{ this.state.friend_installed_array[2]}</td></tr>
          <tr><td>Activity</td><td>{ this.state.today_activity}</td><td></td><td>{ this.state.total_activity}</td></tr>
          <tr><td>New & Has a Friend</td><td>{ this.state.has_friend_array[0]}</td><td>{ this.state.has_friend_array[1]}</td><td>{ this.state.has_friend_array[2]}</td></tr>
          <tr><td>New & Has a Friend w/ Login</td><td>{ this.state.installed_has_friend_array[0]}</td><td>{ this.state.installed_has_friend_array[1]}</td><td>{ this.state.installed_has_friend_array[2]}</td></tr>
          </tbody>
        </table>
        <button style={{marginTop:"10px",fontSize: "20px" } } onClick={this.doexport}>
        Export
      </button>
      <button style={{marginTop:"10px",fontSize: "20px" } } onClick={this.postexport}>
        Export Posts
      </button>
        <div style={{marginLeft: "10px", marginTop: "20px"}}>Clicks</div>
          <table style={{marginLeft: "10px", marginTop: "5px", borderStyle: "solid"}}>
          <thead><tr><th style={{width: "30%"}}></th>
          <th style={{width: "20%"}}>Total Clicks/Uses</th>
          </tr></thead>
          <tbody>{ report_clicks}</tbody>
          </table>

          <table style={{marginLeft: "10px", marginTop: "20px", borderStyle: "solid"}}>
        <thead><tr><th style={{width: "10%"}}></th>
        <th style={{width: "10%"}}>Followers</th>
        <th style={{width: "10%"}}>Good Friends</th>
        <th style={{width: "10%"}}>Private</th>
        </tr></thead>
        <tbody>
          <tr><td>Enjoy With</td><td>{ this.state.can_sync.everyone}</td><td>{ this.state.can_sync.friends }</td><td>{ this.state.can_sync.noone }</td></tr>
          </tbody>
        </table>

        <table style={{marginLeft: "10px", marginTop: "20px", borderStyle: "solid"}}>
        <thead><tr><th style={{width: "10%"}}>Last Record</th>
        <th style={{width: "10%"}}>Currently Open & Not Private</th>
        <th style={{width: "10%"}}>Currently Open & Private</th>
        <th style={{width: "10%"}}>Currently Closed</th>
        <th style={{width: "10%"}}>Last Time Open & Not Private</th>
        <th style={{width: "10%"}}>Last Time Open</th>
        </tr></thead>
        <tbody>
        { report_activity }
          <tr><td>Total Last Activity</td><td>{ this.state.total_normal }</td><td>{ this.state.total_private }</td><td>{ this.state.total_closed }</td><td></td><td></td></tr>
          </tbody>
        </table>

        <table style={{marginLeft: "10px", marginTop: "20px", borderStyle: "solid"}}>
        <thead><tr><th style={{width: "20%"}}>Version's Today</th>
        <th style={{width: "10%"}}>Total</th></tr></thead>
        <tbody>{ report_version }</tbody>
        </table>

        <table style={{marginLeft: "10px", marginTop: "20px", borderStyle: "solid"}}>
        <thead><tr><th style={{width: "20%"}}>Counts for Installed Users</th>
        <th style={{width: "10%"}}>Friends</th><th style={{width: "10%"}}>Followers</th><th style={{width: "10%"}}>Following</th></tr></thead>
        <tbody>{ report_friends }</tbody>
        </table>
        
        <table style={{marginLeft: "10px", marginTop: "20px", borderStyle: "solid"}}>
        <thead><tr><th style={{width: "20%"}}>Hour (Pacific)</th>
        <th style={{width: "10%"}}>Today</th><th style={{width: "10%"}}>Compare</th><th style={{width: "10%"}}>Total</th></tr></thead>
        <tbody>{ report_hours }</tbody>
        </table>

        <table style={{marginLeft: "10px", marginTop: "20px", borderStyle: "solid"}}>
        <thead><tr><th style={{width: "20%"}}>Hour in Timezone</th>
        <th style={{width: "10%"}}>Today</th><th style={{width: "10%"}}>Compare</th><th style={{width: "10%"}}>Total</th></tr></thead>
        <tbody>{ report_hours_tz }</tbody>
        </table>
        <div style={{marginLeft: "10px", marginTop: "5px", textAlign: "left"}}><span>Limit to Timezone: </span><input
        type='text' onChange={this.changetz}
      /></div>
        <table style={{marginLeft: "10px", marginTop: "20px", borderStyle: "solid"}}>
        <thead><tr><th style={{width: "20%"}}>Time Zone</th>
        <th style={{width: "10%"}}>Today</th><th style={{width: "10%"}}>Compare</th><th style={{width: "10%"}}>Total</th></tr></thead>
        <tbody>{ report_zone }</tbody>
        </table>

        <table style={{marginLeft: "10px", marginTop: "20px", borderStyle: "solid"}}>
        <thead><tr><th style={{width: "20%"}}>Locale</th>
        <th style={{width: "10%"}}>Total</th></tr></thead>
        <tbody>{ report_locales }</tbody>
        </table>

        </div>


  )
}

};


export default App;
